import React from "react"
import { Parallax } from "react-scroll-parallax"
import { Parallelogram, ParallelogramWide } from "components/Icons/Background"
import { Wrapper } from "./styles"

const ParallelogramBG = ({ wide, y, width, height, left, right, color="#D7D7D7" }) => {
  return (
    <Wrapper left={left} right={right}>
      <Parallax y={y}>
        {wide ? (
          <ParallelogramWide width={width} height={height} color={color} />
        ) : (
          <Parallelogram width={width} height={height} color={color} />
        )}
      </Parallax>
    </Wrapper>
  )
}

export { ParallelogramBG }
