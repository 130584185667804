import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  left: ${props => (props.left ? props.left : "unset")};
  right: ${props => (props.right ? props.right : "unset")};
  /* top: 50%;
  transform: translateY(-50%); */
  z-index: -2;
  display: unset;
  @media screen and (max-width: ${p => p.theme.breakpoints.tabletSmall}) {
    display: none;
  }
`
