import React from "react"
import { Container } from "./styles"
import { Parallax } from "react-scroll-parallax"

const BrushedTextContainer = ({ x, y, posY, children }) => {
  return (
    <Parallax styleInner={{zIndex: -1, position: "relative"}} styleOuter={{transform: `translateY(${posY})`, zIndex: -1, position: "relative"}} x={x} y={y}>
      <Container>{children}</Container>
    </Parallax>
  )
}

export { BrushedTextContainer }
